.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* input[type=text] {
  padding: 10px;
} */

input[type=text] {
  /* padding:10px;
  margin:10px 0; */
  caret-color: white;
}

input[type=text] label {
  /* padding:10px;
  margin:10px 0; */
  text-align: left;
}

.border-removed-input {
  /* border: none; */
  outline: none;
  background-color: #282c34;
  color: white;
}

.border-bottom-input {
  border: 0;
  border-bottom:1px solid #eee;
  font-size: 1.5rem;
}

.signup-container {
  display: flex;
  justify-content: center; /* Horizontally centered */
  align-items: center; /* Vertically centered */
  height: 10vh; /* Adjust the height as needed */
}

.leftalign-div {
  text-align: left;
}

.right-aligned-input {
  text-align: right;
}

.stacked-div {
  display: block; /* Ensure divs stack vertically */
  margin-bottom: 10px; /* Add spacing between stacked divs */
  margin-top: 50px;
}

.submit-button {
  background-color: #282c34;
  color: white;
  border-color: white;
  font-size: 1.2rem;
  border-radius: 10px;
  margin-top: 20px;
}


.signup-form {
  border-color: white;
}

.app-description {
  /* width: 50%; */
  /* display: flex; */
  /* justify-content: center;
  align-items: center; */
  /* text-align: center; */
}

.description-child {
  position: relative;
  /* top: 50%; */
  left: 50%;
  width: 60%;
  transform: translate(-50%, -50%);
}
